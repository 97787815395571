import React, { useState, useEffect } from "react";

const TimerBox = ({ value, label }) => (
  <div className="flex flex-col items-center justify-center bg-white bg-opacity-20 rounded-lg p-4 w-24 h-24 backdrop-blur-sm">
    <div className="text-4xl font-bold text-white">{value}</div>
    <div className="text-sm text-white opacity-80">{label}</div>
  </div>
);

const CountdownTimer = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const target = new Date(targetDate).getTime();

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const difference = target - now;

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((difference % (1000 * 60)) / 1000),
        });
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <>
      <div className="text-3xl font-semibold mb-8">Launching in:</div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-12">
        <TimerBox value={timeLeft.days} label="Days" />
        <TimerBox value={timeLeft.hours} label="Hours" />
        <TimerBox value={timeLeft.minutes} label="Minutes" />
        <TimerBox value={timeLeft.seconds} label="Seconds" />
      </div>
    </>
  );
};

export default CountdownTimer;
