import React from 'react';
import './index.css';
import Header from './components/Header';
import UnderConstruction from './components/UnderConstruction';
import CountdownTimer from './components/CountdownTimer';
import ContactSection from './components/ContactInfo';

const App = () => {
  const contacts = ["pablo@advia.tech", "jaime@advia.tech", "miguelon@advia.tech"];
  const launchDate = '2024-10-18T00:00:00';

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-light-blue to-dark-blue text-white p-4">
      <Header title="Advia" />

      <div className="mb-12">
        <UnderConstruction />
      </div>

      <CountdownTimer targetDate={launchDate} />

      <ContactSection contacts={contacts} />
    </div>
  );
};

export default App;