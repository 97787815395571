import React from "react";

const UnderConstruction = () => {
  return (
    <div className="bg-white bg-opacity-20 border-none text-white backdrop-blur-sm max-w-md rounded-lg p-4">
      <div className="flex items-center mb-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 mr-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span className="text-xl font-semibold">Under Construction</span>
      </div>
      <p className="text-white opacity-80">
        We are currently building our webpage. Please check back soon!
      </p>
    </div>
  );
};

export default UnderConstruction;
