import React from "react";

const ContactInfo = ({ email }) => (
  <div className="flex items-center space-x-2 text-white">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
    </svg>
    <a href={`mailto:${email}`} className="hover:underline">
      {email}
    </a>
  </div>
);

const ContactSection = ({ contacts }) => (
  <div className="bg-white bg-opacity-20 rounded-lg p-6 backdrop-blur-sm">
    <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
    <div className="space-y-2">
      {contacts.map((email, index) => (
        <ContactInfo key={index} email={email} />
      ))}
    </div>
  </div>
);

export default ContactSection;
